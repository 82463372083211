import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '4rem 0',
		[theme.breakpoints.down('xs')]: {
			padding: '0 0 2rem 0',
		},
	},
}));

export const FeaturedLogos = ({ featuredInLogos, featuredHeader }) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Grid
				container
				direction='row'
				justifyContent='center'
				alignItems='center'
				style={{ marginBottom: '1rem' }}>
				<Grid item xs={12}>
					<Typography
						variant='h2'
						color='primary'
						style={{
							fontWeight: 500,
							fontSize: '1.8rem',
							textAlign: 'center',
						}}>
						{featuredHeader}
					</Typography>
				</Grid>
			</Grid>
			<Grid
				// style={{
				// 	padding: '1rem 0',
				// 	border: '1px solid #fffff',
				// 	boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.3)',
				// 	borderRadius: '8px',
				// }}
				container
				direction='row'
				justifyContent={featuredInLogos.length < 3 ? 'center' : 'space-around'}
				alignItems='center'
				spacing={2}>
				{featuredInLogos.map((logo, index) => (
					<Grid
						container
						direction='row'
						justifyContent='center'
						item
						xs={12}
						sm={6}
						md={3}
						key={index}>
						<GatsbyImage
							image={logo.featuredInLogo.asset.gatsbyImageData}
							alt={logo.featuredInLogoTitle}
							//THIS IS WHERE THE IMAGES ARE TOO BIG
							// style={{ width: '425px' }}
						/>
					</Grid>
				))}
			</Grid>
		</div>
	);
};
