import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { SupportedProductCard } from './SupportedProductCard';

const useStyles = makeStyles((theme) => ({
	header: {
		fontWeight: 700,
		color: theme.workwaveBlue,
	},
	subHeader: {
		color: '#5A7184',
	},
}));

export const SupportedProducts = ({ header, subheader, supportedProducts }) => {
	const classes = useStyles();

	const med = useMediaQuery('(max-width:600px)');
	return (
		<div
			style={{
				padding: '10rem 0 4rem 0',
				marginBottom: '-12rem',
				marginTop: med && '-2rem',
			}}>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignContent='center'
				spacing={2}
				style={{ textAlign: 'center' }}>
				<Grid item>
					<Typography variant='h4' className={classes.header}>
						{header}
					</Typography>
				</Grid>

				<Grid item xs={12} md={8}>
					<Typography body='body1' className={classes.subHeader}>
						{subheader}
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				direction='row'
				justifyContent='space-around'
				spacing={4}
				style={{ paddingTop: '2rem' }}>
				{supportedProducts.cards.map((supportedProduct, index) => (
					<Grid
						item
						xs={12}
						sm={10}
						md={supportedProducts.cards.length > 2 ? 4 : 6}
						lg={supportedProducts.cards.length > 2 ? 4 : 5}
						key={index}>
						<SupportedProductCard supportedProduct={supportedProduct} />
					</Grid>
				))}
			</Grid>
		</div>
	);
};
