import React, {
	useState,
	useEffect,
	memo,
	useRef,
	useContext,
	lazy,
	Suspense,
} from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { OverviewFeature } from './Features/OverviewFeature';
import { BodyFeatureCard } from './Features/BodyFeatureCard';
import { BodyFeature } from './Features/BodyFeature';
import { Testimonials } from '../Testimonials';
import { MobileFeatures } from './Features/Mobile/MobileFeatures';
// import { TestimonialCarousel } from '../Testimonials/TestimonialCarousel';
import { RmSpecialSection } from './Features/RmSpecialSection';
import { CustomerLogos } from '../CustomerLogos';

const TestimonialCarousel = loadable(() =>
	import('../../components/Testimonials/TestimonialCarousel')
);

const useStyles = makeStyles((theme) => ({
	testimonialBackground: {
		height: '100%',
		width: '99vw',
		padding: '4rem 0',
		[theme.breakpoints.down('md')]: {
			width: '100vw',
		},
	},
	testimonialTitle: {
		color: theme.white,
		fontWeight: 700,
		padding: '2rem 0',
	},
}));

export const IndustryBody = ({
	industry,
	iosLogo,
	androidLogo,
	qbLogo,
	zapierLogo,
	shopifyLogo,
	setFeatureModalOpen,
}) => {
	const classes = useStyles();

	const formRef = useRef();
	const featureRef = useRef(null);

	//card array that gets modified on click. set brand new array to be manipulated on mount
	const [cardArray, setCardArray] = useState([]);

	useEffect(() => {
		setCardArray(industry.featureSection);
	}, []);

	const setToFirst = (selected) => {
		const filteredArray = cardArray.filter(
			(item) => item._key !== selected._key
		);
		setCardArray([selected, ...filteredArray]);
	};

	//handles scrolling to selected feature => currently pointed at the div above the cards on desktop view
	const handleSelectFeature = async (e, content) => {
		await setSelectedFeature(content._key);
		setToFirst(content);
		featureRef?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	//state for selected card for features
	const [selectedFeature, setSelectedFeature] = useState(null);

	useEffect(() => {
		setSelectedFeature(industry.featureSection[0]?._key);
	}, []);

	const med = useMediaQuery('(max-width: 960px)');
	const lg = useMediaQuery('(max-width: 1280px)');

	const {
		overviewHeader,
		_rawOverviewBody,
		featureSection,
		featureHeader,
		accentColor,
		softwareFeatures,
		testimonial,
		customerLogos,
		customerLogosHeader,
		rmSpecialFeature,
		overviewImage,
		testimonialHeader,
		testimonialTitle,
	} = industry;
	return (
		<>
			<Container>
				<OverviewFeature
					overviewHeader={overviewHeader}
					overviewImage={overviewImage}
					overviewBody={_rawOverviewBody}
				/>
				{med ? (
					<MobileFeatures
						featureSection={featureSection}
						featureHeader={featureHeader}
						featureRef={featureRef}
						selectedFeature={selectedFeature}
						accentColor={accentColor[0].hexValue ?? '#002D5C'}
						iosLogo={iosLogo}
						androidLogo={androidLogo}
						qbLogo={qbLogo}
						featureListCta={softwareFeatures?.featureListCta}
						setFeatureModalOpen={setFeatureModalOpen}
					/>
				) : (
					<BodyFeatureCard
						featureHeader={featureHeader}
						featureSection={featureSection}
						selectedFeature={selectedFeature}
						handleClick={handleSelectFeature}
						featureRef={featureRef}
						featureListCta={softwareFeatures?.featureListCta}
						setFeatureModalOpen={setFeatureModalOpen}
						accentColor={accentColor[0].hexValue ?? '#002D5C'}
					/>
				)}
			</Container>

			<Container>
				{!med &&
					cardArray
						.slice(0, 2)
						.map((feature, index) => (
							<BodyFeature
								feature={feature}
								index={index}
								key={index}
								selectedFeature={selectedFeature}
								accentColor={accentColor[0].hexValue}
								iosLogo={iosLogo}
								androidLogo={androidLogo}
								qbLogo={qbLogo}
								setSelectedFeature={setSelectedFeature}
							/>
						))}
			</Container>

			<div
				className={classes.testimonialBackground}
				style={{
					background: `linear-gradient(180deg, ${accentColor[0].hexValue} 50%, #FFFFFF 50%)`,
				}}>
				<Container>
					<Grid
						container
						direction='row'
						justifyContent='center'
						alignItems='center'>
						<Grid item xs={12} style={{ textAlign: 'center' }}>
							<Typography variant='h2' className={classes.testimonialTitle}>
								{testimonialTitle}
							</Typography>
						</Grid>
					</Grid>
					{med ? (
						<Grid
							container
							direction='row'
							justifyContent='center'
							alignItems='center'>
							<TestimonialCarousel
								carouselTestimonialArray={testimonial}
								ref={formRef}
							/>
						</Grid>
					) : (
						<Testimonials
							header={testimonialHeader}
							testimonials={testimonial}
							formRef={formRef}
						/>
					)}
				</Container>
			</div>
			<Container>
				<CustomerLogos
					customerLogosArray={customerLogos}
					customerLogosHeader={customerLogosHeader}
				/>
			</Container>
			<div className={classes.featureBackground}>
				<Container>
					{!med &&
						cardArray
							.slice(-2)
							.map((feature, index) => (
								<BodyFeature
									feature={feature}
									index={index}
									key={index}
									selectedFeature={selectedFeature}
									accentColor={accentColor[0].hexValue}
									iosLogo={iosLogo}
									androidLogo={androidLogo}
									qbLogo={qbLogo}
									setSelectedFeature={setSelectedFeature}
								/>
							))}
					{rmSpecialFeature && (
						<RmSpecialSection
							feature={rmSpecialFeature}
							accentColor={accentColor[0].hexValue}
							zapierLogo={zapierLogo}
							shopifyLogo={shopifyLogo}
						/>
					)}
				</Container>
			</div>
		</>
	);
};
